import React from "react";

import Layout from "../../../layouts/Layout";
import VerticalBanner from "../../../partials/VerticalBanner";
import VerticalCategoriesSection from "../../../partials/VerticalCategoriesSection";
import VerticalCaseStudySection from "../../../partials/VerticalCaseStudySection";
import VerticalInfoBanner from "../../../partials/VerticalInfoBanner";
import PlatformSection from "../../../partials/PlatformSection";
import VerticalPartnersSection from "../../../partials/VerticalPartnersSection";
import VerticalNetworkSection from "../../../partials/VerticalNetworkSection";

import { useMediaQuery } from "../../../utils/hooks";

const RetailGroceryPage = props => {
  const {
    pageContext: { caseStudy },
  } = props;

  const isMobile = useMediaQuery("(max-width: 575px)");

  return (
    <Layout
      pageTitle="Brandcrush for Retailers"
      ogTitle="Brandcrush for Grocery Retailers | Streamline and scale supplier marketing bookings for in-store and out-of-store activations"
      ogImage="/images/retail@3x.png"
      ogDescription="Transform media sales for your business with an end-to-end software solution. Make it easy for suppliers to find and book marketing campaigns in-store and out-of-store with a self-serve media platform."
    >
      <VerticalBanner
        vertical="Retail & grocery"
        heading="Unlock your omnichannel retail media potential"
        subHeading="Maximize your revenue and transform manual media bookings, all in one powerfully simple platform."
        backgroundImgSrc="/images/cloud-vertical-purple.svg"
        imageSrc="/images/retail@3x.png"
      />
      <VerticalCategoriesSection
        heading="Transform media sales for your business with an end-to-end software solution"
        subHeading="Make it easy for suppliers to find and book shopper marketing across your entire retail media ecosystem with a scalable, self-serve platform."
        categories={[
          {
            label: "In-store",
            iconSrc: "/images/onsite.svg",
            activationTypes: [
              "Digital screens",
              "Popups",
              "Point of sale displays",
              "Sampling",
              "Radio",
              "Experiences",
              "Brand placements",
              "Off-location displays",
            ],
            color: "light-blue",
          },
          {
            label: "Out-of-store",
            iconSrc: "/images/home.svg",
            activationTypes: [
              "Circulars",
              "In-box sampling",
              "Curb-side sampling",
              "In-box inserts",
              "Mailer inserts",
              "Magazines",
            ],
            color: "blue",
          },
          {
            label: "Online",
            iconSrc: "/images/online.svg",
            activationTypes: [
              "Circulars",
              "Website promotion",
              "Digital sponsorship",
              "Social sponsorship",
              "Email sponsorship",
              "Digital catalogues",
            ],
            color: "topaz",
          },
        ]}
      />
      <VerticalCaseStudySection caseStudy={caseStudy} />
      <PlatformSection
        topHeading="Maximize your media sales"
        topCopy="Grow and scale revenue with custom platform tools."
        topGraphics={[
          {
            image: "/images/window@3x.png",
            x: 0,
            y: 0,
            delay: 0.0,
            height: isMobile ? 14.75 : 32.5,
          },
          {
            image: "/images/sales-graph@3x.png",
            x: isMobile ? 1.406 : 3.125,
            y: isMobile ? 4.404 : 9.688,
            delay: 0.2,
            width: isMobile ? 15.938 : 35.063,
          },
          {
            image: "/images/sales-stats@3x.png",
            x: isMobile ? 1.406 : 3.125,
            y: isMobile ? 1.847 : 4.063,
            delay: 0.4,
            width: isMobile ? 15.938 : 35.063,
          },
        ]}
        topButtonLabel="Schedule a demo"
        topButtonHref="/book-a-demo"
        middleHeading="Streamline your bookings"
        middleCopy="Manage and scale your bookings with inventory tools, booking calendars and campaign workflows."
        middleGraphics={[
          {
            image: "/images/window@3x.png",
            x: 0,
            y: 0,
            delay: 0.0,
            height: isMobile ? 14.75 : 32.5,
          },
          {
            image: "/images/activations-logo-retail@3x.png",
            x: isMobile ? 1.42 : 3.125,
            y: isMobile ? 10.417 : 22.917,
            delay: 0.2,
            height: isMobile ? 2.273 : 5.0,
          },
          {
            image: "/images/activations-name@3x.png",
            x: isMobile ? 4.375 : 9.625,
            y: isMobile ? 11.364 : 25.0,
            delay: 0.2,
            height: isMobile ? 0.369 : 0.8125,
          },
          {
            image: "/images/activations-table@3x.png",
            x: isMobile ? 1.932 : 4.25,
            y: isMobile ? 2.178 : 4.792,
            delay: 0.4,
            height: isMobile ? 7.188 : 15.8125,
          },
        ]}
        middleButtonLabel="Schedule a demo"
        middleButtonHref="/book-a-demo"
        bottomHeading="Simplify your payments"
        bottomCopy="Secure and trusted vendor management, payments and collections, or integrate with your existing finance systems."
        bottomGraphics={[
          {
            image: "/images/window@3x.png",
            x: 0,
            y: 0,
            delay: 0.0,
            height: isMobile ? 14.75 : 32.5,
          },
          {
            image: "/images/payments-summary@3x.png",
            x: isMobile ? 1.136 : 2.5,
            y: isMobile ? 4.318 : 9.5,
            delay: 0.2,
            width: isMobile ? 7.67 : 16.875,
          },
          {
            image: "/images/payments-table@3x.png",
            x: isMobile ? 9.943 : 21.875,
            y: isMobile ? 2.159 : 4.75,
            delay: 0.4,
            width: isMobile ? 7.67 : 16.875,
          },
          {
            image: "/images/payments-button@3x.png",
            x: isMobile ? 1.932 : 4.25,
            y: isMobile ? 2.159 : 4.75,
            delay: 0.6,
            width: isMobile ? 6.051 : 13.313,
          },
        ]}
        bottomButtonLabel="Schedule a demo"
        bottomButtonHref="/book-a-demo"
        lastHeading="Integrate systems"
        lastCopy="Integrate with finance, CRM and partner platforms"
        lastGraphics={[
          {
            image: "/images/window@3x.png",
            x: 0,
            y: 0,
            delay: 0.0,
            height: isMobile ? 14.75 : 32.5,
          },
          {
            image: "/images/integrate@3x.png",
            x: isMobile ? 6.279 : 13.813,
            y: isMobile ? 4.261 : 9.375,
            delay: 0.1,
            width: isMobile ? 6.506 : 14.313,
          },
          {
            image: "/images/integrate-cog-1@3x.png",
            x: isMobile ? 4.689 : 10.25,
            y: isMobile ? 10.739 : 23.625,
            delay: 0.3,
            width: isMobile ? 1.193 : 2.625,
          },
          {
            image: "/images/integrate-cog-1@3x.png",
            x: isMobile ? 12.955 : 28.5,
            y: isMobile ? 2.784 : 6.125,
            delay: 0.4,
            width: isMobile ? 1.193 : 2.625,
          },
          {
            image: "/images/integrate-cog-2@3x.png",
            x: isMobile ? 13.551 : 29.813,
            y: isMobile ? 7.045 : 15.5,
            delay: 0.5,
            width: isMobile ? 1.534 : 3.375,
          },
          {
            image: "/images/integrate-cog-3@3x.png",
            x: isMobile ? 12.585 : 27.688,
            y: isMobile ? 10.654 : 23.438,
            delay: 0.6,
            width: isMobile ? 1.932 : 4.25,
          },
          {
            image: "/images/integrate-cog-4@3x.png",
            x: isMobile ? 3.55 : 7.813,
            y: isMobile ? 2.216 : 4.875,
            delay: 0.7,
            width: isMobile ? 2.443 : 5.375,
          },
        ]}
        lastButtonLabel="Schedule a demo"
        lastButtonHref="/book-a-demo"
        noActionButtons
        signupHref="/book-a-demo"
      />
      <VerticalInfoBanner
        heading="Your media, your platform"
        subHeading="Drive your media sales with a fully customized and branded media platform, integrated with your website."
        checklistItems={[
          "White label media sites",
          "Custom acitvation types & categories",
          "Custom campaign workflows",
          "Multicurrency & multi-location",
        ]}
      />
      <VerticalPartnersSection heading="Opt in to promote your media to thousands of new brands via our marketplace" />
      <VerticalNetworkSection
        heading="Join a growing network of retailers unlocking and growing their media revenue"
        logos={[
          {
            src: "/images/logos/partners/Supabarn_grey.png",
            alt: "",
            width: isMobile ? "4.5" : "5.688",
          },
          {
            src: "/images/logos/partners/blooms_grey.png",
            alt: "",
            width: isMobile ? "5" : "6",
          },
          {
            src: "/images/logos/partners/HillSt_grey.png",
            alt: "",
            width: isMobile ? "4.5" : "5.3",
          },
          {
            src: "/images/logos/retail/HelloFresh_Grey.png",
            alt: "",
            width: isMobile ? "3.25" : "5.25",
          },
          {
            src: "/images/logos/retail/CultureKings_Grey.png",
            alt: "",
            width: isMobile ? "7.5" : "12.0",
          },
          {
            src: "/images/logos/retail/Kohls_Grey.png",
            alt: "",
            width: isMobile ? "6.25" : "10.0",
          },
          {
            src: "/images/logos/retail/MarleySpoon_Grey.png",
            alt: "",
            width: isMobile ? "4.375" : "7.063",
          },
          {
            src: "/images/logos/retail/Catch_Grey.png",
            alt: "",
            width: isMobile ? "4.18" : "6.688",
          },
        ]}
        height="3.964"
        background-color="charcoal-15"
        autoplay
      />
    </Layout>
  );
};

export default RetailGroceryPage;
